import React from "react";
import {
  TextField,
  Button,
  Icon,
  CardContent,
  Card,
  Typography,
  Divider,
  Link,
  Container,
  CssBaseline,
  Grid,
  Box,
  Collapse,
  Badge,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "./App.css";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";

const apiForAction = {
  confirm:
    "https://nzc6ks1tgg.execute-api.eu-central-1.amazonaws.com/live/confirm",
  reject:
    "https://nzc6ks1tgg.execute-api.eu-central-1.amazonaws.com/live/reject",
};

const App = () => {
  const [response, setResponse] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [dataID, setDataID] = React.useState({
    _id: "",
    boxName: "",
    person: 0,
    datum: "",
    time: "",
    name: "",
    message: "",
    email: "",
    phone: "",
    year: 0,
    saveDate: "",
    orderState: "initial",
  });
  const location = typeof window !== "undefined" ? window.location : undefined;
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id"); // uuid v4: a2b1540e-8757-4463-bbe4-9b3220522f8b
  const action = urlParams.get("action"); // could be "confirm" | "reject"
  const isValid = (action === "confirm" || action === "reject") && id;
  const [open, setOpen] = React.useState(false);
  const [sending, setSending] = React.useState(false);

  React.useEffect(() => {
    if (isValid) {
      fetch(
        "https://nzc6ks1tgg.execute-api.eu-central-1.amazonaws.com/live/findOne",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async (res) => {
        const resp = await res.json();
        setDataID(resp.data);
      });
    }
  }, [isValid, id]);

  const useStyles = makeStyles((theme) => ({
    paper: {
      paddingTop: theme.spacing(5),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      paddingTop: theme.spacing(5),
    },
    badge: {
      width: "100%", // Fix IE 11 issue.
    },
    card: {
      width: "100%", // Fix IE 11 issue.
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }));

  const classes = useStyles();
  const label =
    dataID.orderState === "initial"
      ? "Noch nicht besätigt"
      : dataID.orderState === "confirmed"
      ? "Besätigt"
      : dataID.orderState === "rejected"
      ? "Stoniert"
      : "unbekannt";

  const labelColor =
    dataID.orderState === "inital"
      ? "secondary"
      : dataID.orderState === "confirmed"
      ? "primary"
      : "error";
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Bestellung{" "}
          {action === "confirm" ? (
            <strong>bestätigen</strong>
          ) : action === "reject" ? (
            <strong>stonieren</strong>
          ) : (
            <></>
          )}
          .
        </Typography>

        <form
          className={classes.form}
          autoComplete="off"
          onSubmit={($event) => {
            $event.preventDefault();
            if (isValid) {
              setSending(true);
              fetch(apiForAction[action], {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  id: id,
                  message: message.replace(/\n/g, "<br />"),
                }),
              }).then(async (res) => {
                const resp = await res.json();
                setResponse(resp.message);
                setOpen(true);
                setSending(false);
              });
            }
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {(dataID.orderState === "confirmed" ||
                dataID.orderState === "rejected") && (
                <Alert severity="warning">
                  Achtung die Bestellung wurde bereits beantwortet.
                </Alert>
              )}
            </Grid>

            <Grid item xs={12}>
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                badgeContent={`Status: ${label}`}
                color={labelColor}
                className={classes.badge}
              >
                <Card fullWidth className={classes.card}>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {dataID.boxName} für {dataID.person} PAX
                    </Typography>
                    <Typography variant="h5" component="h2">
                      {dataID.datum} um {dataID.time} Uhr
                      <Divider />
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      {dataID.name}
                      <br />
                      {dataID.email} - {dataID.phone}
                    </Typography>
                    <Typography variant="subtitle1" component="p" gutterBottom>
                      <strong>Nachricht:</strong>
                      <div
                        dangerouslySetInnerHTML={{ __html: dataID.message }}
                      />
                    </Typography>
                    <Divider />
                    <Typography color="textSecondary">
                      Bestellt am {dataID.saveDate}
                    </Typography>
                  </CardContent>
                </Card>
              </Badge>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-textarea"
                label="Nachricht an den Kunden"
                placeholder=""
                fullWidth
                rows={10}
                value={message}
                onChange={($event) => setMessage($event.target.value)}
                multiline
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Typography variant="caption" display="block" gutterBottom>
            Wenn hier keine Nachricht eingetragen wird. Wird diese ohne
            besonderen Informationen zu{" "}
            {action === "confirm" ? (
              <strong>bestätigen</strong>
            ) : action === "reject" ? (
              <strong>stonieren</strong>
            ) : (
              ""
            )}
            .
          </Typography>

          <Grid item xs={12}>
            <Collapse in={open}>
              <Alert
                severity={response}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {response === "success"
                  ? "Die bestellung wurde Erfolgreich beantwortet!"
                  : "Es ist ein Fehler aufgetreten, es sollte keine E-Mail versendet werden."}
              </Alert>
            </Collapse>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Icon>send</Icon>}
            type="submit"
            disabled={!isValid || sending}
            className={classes.submit}
          >
            Auf Bestellung Antworten
          </Button>
        </form>
      </div>

      <Box mt={5}>
        <Typography variant="body2" color="textSecondary" align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://nbank.dev/">
            Developed by nbank dot dev
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Box>
    </Container>
  );
};

export default App;
